import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./kripto.css";

function Kripto() {
    const [kriptoFiyatlari, setKriptoFiyatlari] = useState([]);

    useEffect(() => {
        axios.get('https://api.coincap.io/v2/assets')
            .then(response => {
                // Veriyi alınan formata uygun şekilde dönüştürün
                const formattedData = response.data.data.map((item, index) => ({
                    id: index + 1,
                    isim: item.name,
                    fiyat: Number(item.priceUsd).toFixed(2),
                    marketCap: Number(item.marketCapUsd).toLocaleString(),
                    degisimOrani: Number(item.changePercent24Hr).toFixed(2)
                }));
                setKriptoFiyatlari(formattedData);
            })
            .catch(error => {
                console.error('API veri getirme hatası:', error);
            });
    }, []);

    const getDegisimOraniClass = (degisimOrani) => {
        if (degisimOrani < 0) {
            return 'text-center text-danger'; // Negatif ise kırmızı renk
        } else {
            return 'text-center text-primary'; // Pozitif ise mavi renk
        }
    };

    return (
        <div className='kripto mb-5'>
            <div className="container p-0">
            <h1 className='h3 kriptoTitle'>Kripto Para Fiyatları</h1>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="th-style">#</th>
                                    <th className="text-left th-style">Kripto Para</th>
                                    <th className="text-center th-style">Fiyat (USD)</th>
                                    <th className="text-center th-style">Market Cap (USD)</th>
                                    <th className="text-center th-style">24h Değişim (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {kriptoFiyatlari.map((kripto) => (
                                    <tr key={kripto.id} className="hover-effect">
                                        <th scope="row">{kripto.id}</th>
                                        <td className="text-left">{kripto.isim}</td>
                                        <td className="text-center">{kripto.fiyat}</td>
                                        <td className="text-center">{kripto.marketCap}</td>
                                        <td className={getDegisimOraniClass(kripto.degisimOrani)}>{kripto.degisimOrani}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Kripto;
