import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faCloudSun,
  faCloud,
  faCloudRain,
  faSnowflake,
  faWind,
} from "@fortawesome/free-solid-svg-icons";
import "./weather.css"; // Import your CSS file for styles

const Weather = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("İstanbul");
  const [weatherData, setWeatherData] = useState({});
  const [weatherIcon, setWeatherIcon] = useState(null);

  const WEATHER_API_KEY = "c09aaffbb0c1063cc0f3ca3f4ab88c22";
  const cities = [
    "Adana",
    "Adıyaman",
    "Afyonkarahisar",
    "Ağrı",
    "Amasya",
    "Ankara",
    "Antalya",
    "Artvin",
    "Aydın",
    "Balıkesir",
    "Bilecik",
    "Bingöl",
    "Bitlis",
    "Bolu",
    "Burdur",
    "Bursa",
    "Çanakkale",
    "Çankırı",
    "Çorum",
    "Denizli",
    "Diyarbakır",
    "Edirne",
    "Elazığ",
    "Erzincan",
    "Erzurum",
    "Eskişehir",
    "Gaziantep",
    "Giresun",
    "Gümüşhane",
    "Hakkâri",
    "Hatay",
    "Isparta",
    "Mersin",
    "İstanbul",
    "İzmir",
    "Kars",
    "Kastamonu",
    "Kayseri",
    "Kırklareli",
    "Kırşehir",
    "Kocaeli",
    "Konya",
    "Kütahya",
    "Malatya",
    "Manisa",
    "Kahramanmaraş",
    "Mardin",
    "Muğla",
    "Muş",
    "Nevşehir",
    "Niğde",
    "Ordu",
    "Rize",
    "Sakarya",
    "Samsun",
    "Siirt",
    "Sinop",
    "Sivas",
    "Tekirdağ",
    "Tokat",
    "Trabzon",
    "Tunceli",
    "Şanlıurfa",
    "Uşak",
    "Van",
    "Yozgat",
    "Zonguldak",
    "Aksaray",
    "Bayburt",
    "Karaman",
    "Kırıkkale",
    "Batman",
    "Şırnak",
    "Bartın",
    "Ardahan",
    "Iğdır",
    "Yalova",
    "Karabük",
    "Kilis",
    "Osmaniye",
    "Düzce",
  ];
  const defaultCities = ["Ankara", "İzmir", "Bursa", "Gaziantep"];

  useEffect(() => {
    setFilteredCities(cities);
    fetchWeatherForDefaultCities(); // Get weather data for default cities initially
    getWeather("İstanbul"); // Get weather data for Istanbul initially
  }, []);

  const fetchWeatherForDefaultCities = async () => {
    const weatherPromises = defaultCities.map((city) =>
      fetch(
        `https://api.openweathermap.org/data/2.5/weather?q=${city}&appid=${WEATHER_API_KEY}&units=metric`
      ).then((response) => response.json())
    );
    const weatherResults = await Promise.all(weatherPromises);
    const weatherDataObject = weatherResults.reduce((acc, data) => {
      acc[data.name] = data;
      return acc;
    }, {});
    setWeatherData(weatherDataObject);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setFilteredCities(
      cities.filter((city) =>
        city.toLocaleLowerCase("tr").includes(term.toLocaleLowerCase("tr"))
      )
    );
  };

  const getWeather = async (cityName) => {
    setSelectedCity(cityName);

    const response = await fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${WEATHER_API_KEY}&units=metric`
    );
    const data = await response.json();
    setWeatherData((prevState) => ({
      ...prevState,
      [cityName]: data,
    }));

    updateWeatherIcon(data.weather[0].description);
  };

  const updateWeatherIcon = (description) => {
    switch (description.toLowerCase()) {
      case "clear sky":
        setWeatherIcon(faSun);
        break;
      case "few clouds":
      case "scattered clouds":
      case "broken clouds":
        setWeatherIcon(faCloudSun);
        break;
      case "overcast clouds":
        setWeatherIcon(faCloud);
        break;
      case "light rain":
      case "moderate rain":
      case "heavy intensity rain":
        setWeatherIcon(faCloudRain);
        break;
      case "snow":
        setWeatherIcon(faSnowflake);
        break;
      case "mist":
      case "smoke":
      case "haze":
      case "dust":
      case "fog":
      case "sand":
      case "volcanic ash":
      case "squalls":
        setWeatherIcon(faWind);
        break;
      default:
        setWeatherIcon(faSun);
        break;
    }
  };

  return (
    <div className="havaDurumu mb-2">
      <div className="weather-container">
        <div className="weather-header">
          <div className="titleColor"></div>
          <h1 className="h3 WeatherTitle">Hava Durumu</h1>
        </div>
        <div className="weather-content">
          <div className="cities mb-4">
            <div className="row">
              <div className="col-6 p-0 m-0">
                <div className="stabilCity cityAnk">
                  <div className="city-name">{defaultCities[0]}</div>
                  <div className="city-temp">
                    {weatherData[defaultCities[0]] &&
                      weatherData[defaultCities[0]].main.temp.toFixed(1)}{" "}
                    °C
                  </div>
                </div>
              </div>
              <div className="col-6 p-0 m-0">
                <div className="stabilCity cityIzm">
                  <div className="city-name">{defaultCities[1]}</div>
                  <div className="city-temp">
                    {weatherData[defaultCities[1]] &&
                      weatherData[defaultCities[1]].main.temp.toFixed(1)}{" "}
                    °C
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 p-0 m-0">
                <div className="stabilCity cityTs">
                  <div className="city-name">{defaultCities[2]}</div>
                  <div className="city-temp">
                    {weatherData[defaultCities[2]] &&
                      weatherData[defaultCities[2]].main.temp.toFixed(1)}{" "}
                    °C
                  </div>
                </div>
              </div>
              <div className="col-6 p-0 m-0">
                <div className="stabilCity cityDbk">
                  <div className="city-name">{defaultCities[3]}</div>
                  <div className="city-temp">
                    {weatherData[defaultCities[3]] &&
                      weatherData[defaultCities[3]].main.temp.toFixed(1)}{" "}
                    °C
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="downWeather d-flex">
            <div className="searchBox">
              <div className="searchBoxContent">
                <input
                  className="weather-search-input"
                  placeholder="Şehir ara"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="weather-city-list">
                <ul>
                  {filteredCities.length > 0 ? (
                    filteredCities.map((city, index) => (
                      <li
                        key={index}
                        className={`city-item ${
                          index % 2 === 0 ? "bg-light" : "bg-secondary"
                        }`}
                        onClick={() => getWeather(city)}
                      >
                        {city}
                      </li>
                    ))
                  ) : (
                    <li className="city-item bg-light">
                      Aradığınız şehir bulunamadı.
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="weather-details p-1">
              <div className="selected-city">{selectedCity}</div>
              <div className="contentBox flex">
                <div className="weather-icon">
                  <FontAwesomeIcon icon={weatherIcon} size="2x" />
                </div>
                <div className="weather-temperature">
                  {weatherData[selectedCity] &&
                    weatherData[selectedCity].main.temp.toFixed(1)}
                  °C
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Weather;
