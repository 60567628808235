import React, { useState, useEffect } from "react";
import axios from "axios";
import "./trendnews.css";
import TitleBar from "../titleBar/TitleBar";

const timeFrames = {
  today: "bugün",
  thisWeek: "bu hafta",
  thisMonth: "bu ay",
  thisYear: "bu yıl",
};



const LeagueTable = () => {
  const teams = [
    {
      position: 1,
      name: "Galatasaray",
      played: 38,
      goalDifference: 66,
      points: 102,
    },
    {
      position: 2,
      name: "Fenerbahçe",
      played: 38,
      goalDifference: 68,
      points: 99,
    },
    {
      position: 3,
      name: "Trabzonspor",
      played: 38,
      goalDifference: 19,
      points: 67,
    },
    {
      position: 4,
      name: "Başakşehir",
      played: 38,
      goalDifference: 14,
      points: 61,
    },
    {
      position: 5,
      name: "Kasımpaşa",
      played: 38,
      goalDifference: -3,
      points: 56,
    },
  ];

  // return (
  //   <div className="league-table">
  //     <TitleBar title="Puan Durumu" />
  //     <table>
  //       <thead>
  //         <tr>
  //           <th></th>
  //           <th></th>
  //           <th>O</th>
  //           <th>G</th>
  //           <th>P</th>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {teams.map((team) => (
  //           <tr key={team.position}>
  //             <td>{team.position}</td>
  //             <td>{team.name}</td>
  //             <td>{team.played}</td>
  //             <td>
  //               {team.goalDifference > 0
  //                 ? `+${team.goalDifference}`
  //                 : team.goalDifference}
  //             </td>
  //             <td>{team.points}</td>
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );
};

const AdContainer = ({ ad, handleAdClick }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    adRef.current.innerHTML = '';

    if (ad.imageURL.includes('.html') || ad.imageURL.includes('sadbundle') || ad.imageURL.includes('doubleclick.net')) {
      const iframe = document.createElement('iframe');
      iframe.src = ad.imageURL;
      iframe.frameBorder = '0';
      iframe.allowFullscreen = true;
      iframe.style = 'width: auto; height: 300px; border: 0; overflow: hidden; margin: 0 auto;';
      iframe.scrolling = 'no';


      iframe.height = '300';


      window.addEventListener('message', function (e) {
        if (e.data && e.data.type === 'setAdHeight') {
          iframe.height = e.data.height + 'px';
        }
      }, false);

      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement('img');
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.className = "league-table-ads";
      img.style = 'margin: 0 auto; width: auto; height: auto; object-fit: contain;';
      adRef.current.appendChild(img);
    }
  }, [ad]);

  const handleClick = async (e) => {
    e.preventDefault();
    await handleAdClick(ad._id);
    window.location.href = ad.link;
  };

  return (
    <div className="ad-containerx" onClick={handleClick}>
      <div ref={adRef}></div>
    </div>
  );
};

const Trending = () => {
  const [showTable, setShowTable] = useState(true);
  const [sideMenu1, setSideMenu1] = useState(null);
  const [sideMenu2, setSideMenu2] = useState(null);
  const [sideMenu3, setSideMenu3] = useState(null);
  const [sideMenu4, setSideMenu4] = useState(null);
  const [highlightedNews, setHighlightedNews] = useState([]);
  const [activeTimeFrame, setActiveTimeFrame] = useState("thisWeek");
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("thisWeek");


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tableResponse, adsResponse, newsResponse] = await Promise.all([
          axios.get("https://api.ekonomimanset.com/api/puan-durumu"),
          axios.get("https://api.ekonomimanset.com/api/ads"),
          axios.get("https://api.ekonomimanset.com/by-views?limit=2000")
        ]);

        setShowTable(tableResponse.data.show);

        const ads = adsResponse.data;

        setSideMenu1(ads.find((ad) => ad.title === "Sidemenü Reklam 1" && ad.isActive === true) || null);
        setSideMenu2(ads.find((ad) => ad.title === "Sidemenü Reklam 2" && ad.isActive === true) || null);
        setSideMenu3(ads.find((ad) => ad.title === "Sidemenü Reklam 3" && ad.isActive === true) || null);
        setSideMenu4(ads.find((ad) => ad.title === "Sidemenü Reklam 4 - PUAN DURUMU YERİNE" && ad.isActive === true) || null);

        const newsData = newsResponse.data.news || [];

        await filterNewsByTimeFrame(timeFrames.thisWeek);

        const filteredHighlightedNews = newsData
          .filter((news) => {
            const updateDate = new Date(news.updateDate);
            const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
            return !news.isDraft && updateDate >= twentyFourHoursAgo;
          })
          .slice(0, 10);

        

      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchData();
  }, []);



  const handleTimeFrameChange = async (newTimeFrame) => {
    setActiveTimeFrame(newTimeFrame);
    setSelectedTimeFrame(newTimeFrame);
    filterNewsByTimeFrame(timeFrames[newTimeFrame]);
    await filterNewsByTimeFrame(timeFrames[newTimeFrame]);
  };



  const filterNewsByTimeFrame = async (timeFrame) => {
    try {
      const response = await axios.get(`https://api.ekonomimanset.com/by-views?timeFrame=${timeFrame}&limit=2000`);
      const newsData = response.data.news || [];
      const startOfDay = new Date();
      startOfDay.setHours(0, 0, 0, 0);

      let filteredHighlightedNews;

      if (timeFrame === "bugün") {
      
        filteredHighlightedNews = newsData.filter((news) => {
          const updateDate = new Date(news.updateDate);
          return (
            !news.isDraft &&
            updateDate >= startOfDay &&
            updateDate < new Date(startOfDay.getTime() + 86400000) 
          );
        });
      } else if (timeFrame === "bu hafta") {
        const now = new Date();
        const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000); 
      
        filteredHighlightedNews = newsData.filter((news) => {
          const updateDate = new Date(news.updateDate);
          return (
            !news.isDraft &&
            updateDate >= sevenDaysAgo && 
            updateDate <= now 
          );
        });
      } else if (timeFrame === "bu ay") {
 
        const startOfMonth = new Date();
        startOfMonth.setDate(startOfMonth.getDate() - 30); 
        startOfMonth.setHours(0, 0, 0, 0);

        filteredHighlightedNews = newsData.filter((news) => {
          const updateDate = new Date(news.updateDate);
          return (
            !news.isDraft &&
            updateDate >= startOfMonth &&
            updateDate <= new Date() 
          );
        });
      } else if (timeFrame === "bu yıl") {
       
        const startOfYear = new Date();
        startOfYear.setDate(startOfYear.getDate() - 365); 
        startOfYear.setHours(0, 0, 0, 0);

        filteredHighlightedNews = newsData.filter((news) => {
          const updateDate = new Date(news.updateDate);
          return (
            !news.isDraft &&
            updateDate >= startOfYear &&
            updateDate <= new Date() 
          );
        });
      }

      // console.log("Filtrelenen Haberler:", filteredHighlightedNews);

      setHighlightedNews(filteredHighlightedNews.slice(0, 10));
    } catch (error) {
      console.error("Zaman dilimine göre haber çekme hatası:", error);
    }
};






  const handleAdClick = async (adId) => {
    try {
      await axios.post(`https://api.ekonomimanset.com/api/ads/${adId}/hit`);
    } catch (error) {
      console.error("Hit count artırma hatası:", error);
    }
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`https://api.ekonomimanset.com/${newsId}/increment-view`);
      window.location.href = `/${slug}`; // Aynı sekmede yönlendirme
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      window.location.href = `/${slug}`; // Hata olsa bile aynı sekmede yönlendirme
    }
  };




  return (
    <div className="trends">
      <div className="trending">
        <TitleBar title="En Çok Okunan Haberler" />

        <div className="time-frame-buttons">
          {/* <button
            onClick={() => handleTimeFrameChange("today")}
            style={{
              backgroundColor: activeTimeFrame === "today" ? "#a04343" : "#F87171",
              color: activeTimeFrame === "today" ? "white" : "white"
            }}
          >
            Bugün
          </button> */}
          <button
            onClick={() => handleTimeFrameChange("thisWeek")}
            style={{
              backgroundColor: activeTimeFrame === "thisWeek" ? "#a04343" : "#F87171",
              color: activeTimeFrame === "thisWeek" ? "white" : "white"
            }}
          >
            Son 7 Gün
          </button>
          <button
            onClick={() => handleTimeFrameChange("thisMonth")}
            style={{
              backgroundColor: activeTimeFrame === "thisMonth" ? "#a04343" : "#F87171",
              color: activeTimeFrame === "thisMonth" ? "white" : "white"
            }}
          >
            Son 1 Ay
          </button>
          <button
            onClick={() => handleTimeFrameChange("thisYear")}
            style={{
              backgroundColor: activeTimeFrame === "thisYear" ? "#a04343" : "#F87171",
              color: activeTimeFrame === "thisYear" ? "white" : "white"
            }}
          >
            Son 1 Yıl
          </button>
        </div>

        <ul>
          {highlightedNews.map((item) => (
            <li className="trend-news-item w-full" key={item._id}>
              <div
                className="cursor-pointer w-full flex items-center justify-between "
                onClick={(e) => handleNewsClick(item._id, item.slug, e)}
              >
                <div className="w-11/12">
                <span className="text-[13px] text-neutral-500">{new Date(item.updateDate).toLocaleDateString()}</span><br />
                <span className="text trend-news-title">{item.title}</span>
                </div>
                <span style={{ marginLeft: "5px" }} className="arrow w-max">
                  <span className="material-symbols-outlined">chevron_right</span>
                </span>
              </div>
            </li>
          ))}
        </ul>
        
        <div className="ads-trends my-4">
          {sideMenu1 && <AdContainer ad={sideMenu1} handleAdClick={handleAdClick} />}
          <br />
          {sideMenu2 && <AdContainer ad={sideMenu2} handleAdClick={handleAdClick} />}
          <br />
          {sideMenu3 && <AdContainer ad={sideMenu3} handleAdClick={handleAdClick} />}
          <br />
        </div>
        {showTable ? (
          <LeagueTable />
        ) : (
          <div className="league-table">
            {sideMenu4 && <AdContainer ad={sideMenu4} handleAdClick={handleAdClick} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Trending;