import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "./altin.css";

function Doviz() {
    const [altinFiyatlari, setAltinFiyatlari] = useState([]);

    useEffect(() => {
        axios.get('https://finans.truncgil.com/today.json')
            .then(response => {
                // Veriyi alınan formata uygun şekilde dönüştürün
                const formattedData = Object.keys(response.data)
                    .filter((key, index) => index > 67 && index <84) // sadece id'si 66'ya kadar olan verileri al
                    .map((key, index) => ({
                        id: index + 1,
                        altinTuru: key,
                        alisFiyati: response.data[key]['Alış'],
                        satisFiyati: response.data[key]['Satış'],
                        degisimOrani: response.data[key]['Değişim'],
                        saat: response.data[key]['Saat']
                    }));
                setAltinFiyatlari(formattedData);
            })
            .catch(error => {
                console.error('API veri getirme hatası:', error);
            });
    }, []);
    const getDegisimOraniClass = (degisimOrani) => {
        if (degisimOrani.includes('-')) {
            return 'text-center text-danger'; // Negatif ise kırmızı renk
        } else {
            return 'text-center text-primary'; // Pozitif ise mavi renk
        }
    };

    return (
        <div className='altin mb-5'>
            <div className="container p-0">
                <h1 className="h2 altinTitle">Altın Fiyatları</h1>
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="th-style">#</th>
                                    <th className="text-left th-style">Altın Türü</th>
                                    <th className="text-center th-style">Alış Fiyatı</th>
                                    <th className="text-center th-style">Satış Fiyatı</th>
                                    <th className="text-center th-style">Değişim Oranı</th>
                                    {/* <th className="text-center th-style">Saat</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {altinFiyatlari.map((altin, index) => (
                                    <tr key={altin.id} className="hover-effect">
                                        <th scope="row">{altin.id}</th>
                                        <td className="text-left"><div className="circle"></div>{altin.altinTuru}</td>
                                        <td className="text-center">{altin.alisFiyati}</td>
                                        <td className="text-center">{altin.satisFiyati}</td>
                                        <td className={getDegisimOraniClass(altin.degisimOrani)}>{altin.degisimOrani}</td>
                                        {/* <td className="text-center">{altin.saat}</td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Doviz;
