import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './hisse.css';

function Hisse() {
    const [hisseFiyatlari, setHisseFiyatlari] = useState([]);
    const [hisseSembolleri, setHisseSembolleri] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHisseSembolleri = async () => {
            try {
                const apiKey = 'n32y01QZ1KZmKncd4k4z15PnBBlIErX4'; // Financial Modeling Prep API key
                const response = await axios.get(`https://financialmodelingprep.com/api/v3/search`, {
                    params: {
                        query: 'AA',
                        apikey: apiKey
                    }
                });
                setHisseSembolleri(response.data);
            } catch (err) {
                console.error('Error fetching symbols:', err);
            }
        };

        fetchHisseSembolleri();
    }, []);

    useEffect(() => {
        const fetchHisseData = async () => {
            if (!selectedSymbol) return;
            try {
                const apiKey = 'n32y01QZ1KZmKncd4k4z15PnBBlIErX4'; // Financial Modeling Prep API key
                const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${selectedSymbol}`, {
                    params: {
                        apikey: apiKey
                    }
                });
                const data = response.data.historical.reverse().slice(0, 10).map((entry, index) => ({
                    id: index + 1,
                    isim: entry.name,
                    tarih: entry.date,
                    kapanis: entry.close.toFixed(4), // Adjust to your desired decimal places
                    acilis: entry.open.toFixed(4),   // Adjust to your desired decimal places
                    yuksek: entry.high.toFixed(4),   // Adjust to your desired decimal places
                    dusuk: entry.low.toFixed(4)      // Adjust to your desired decimal places
                }));
                setHisseFiyatlari(data);
                setLoading(false);
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchHisseData();
    }, [selectedSymbol]);

    const handleSymbolChange = (event) => {
        setSelectedSymbol(event.target.value);
    };

    // if (loading) return <div className="hisse">Loading...</div>;
    if (error) return <div className="hisse">Error: {error.message}</div>;

    return (
        <div className="hisse mb-5">
            <div className="container p-0">
                <h1 className='h3 hisseTitle'>Hisse Fiyatları</h1>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-3">
                            <label htmlFor="symbolSelect" className="form-label">Hisse Seçin:</label>
                            <select id="symbolSelect" className="form-select" onChange={handleSymbolChange} value={selectedSymbol}>
                                <option value="">Hisse Seçin...</option>
                                {hisseSembolleri.map((symbol) => (
                                    <option key={symbol.symbol} value={symbol.symbol}>{symbol.symbol} - {symbol.name}</option>
                                ))}
                            </select>
                        </div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="th-style">Tarih</th>
                                    <th className="text-center th-style">Alış</th>
                                    <th className="text-center th-style">Satış</th>
                                    <th className="text-center th-style">Fark</th>
                                    <th className="text-center th-style">Saat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {hisseFiyatlari.map((hisse) => (
                                    <tr key={hisse.id} className="hover-effect">
                                        <td className="text-center">{hisse.tarih}</td>
                                        <td className="text-center">{hisse.acilis}</td>
                                        <td className="text-center">{hisse.kapanis}</td>
                                        <td className="text-center">{((hisse.acilis - hisse.kapanis) / hisse.acilis * 100).toFixed(2)}%</td>
                                        <td className="text-center">{hisse.tarih}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Hisse;
