import React, { useEffect, useState } from "react";
import axios from "axios";
import TitleBar from "../titleBar/TitleBar";
import "./smallcards.css";

const API_URL = "https://api.ekonomimanset.com/highlighted-news?limit=12";
const API_BASE_URL = "https://api.ekonomimanset.com";

const NewsGrid = () => {
  const [newsItems, setNewsItems] = useState([]);

  useEffect(() => {
    axios
      .get(API_URL)
      .then((response) => {
        // console.log(response.data);
        const filteredPosts = response.data
          .filter((item) => item.isDraft === false)
          .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
          .slice(0, 12);

        setNewsItems(filteredPosts);
      })
      .catch((error) => {
        console.error("Error fetching highlighted news:", error);
      });
  }, []);

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Görüntülenme sayısını artır
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Haberi aynı sekmede aç
      window.location.href = `/${slug}`;
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      // Hata olsa bile haberi aynı sekmede açmaya devam et
      window.location.href = `/${slug}`;
    }
  };

  return (
    <div className="news-grid-container">
      <TitleBar title="Öne Çıkan Haberler" /> <br />
      <div className="news-grid">
        {newsItems.length === 0 ? (
          <p>Öne çıkan haberlere ulaşılamadı.</p>
        ) : (
          newsItems.map((item) => (
            <div key={item._id} className="news-item w-100 h-100">
              <div
                className="news-link cursor-pointer"
                onClick={(e) => handleNewsClick(item._id, item.slug, e)}
              >
                <img
                  src={item.image || "default-image-url.jpg"}
                  alt={item.title}
                />
                <div className="news-content">
                  <h3 className="title">{item.title}</h3>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NewsGrid;
