import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import "../../pages/search/searchpage.css";
import SideMenu from "../../components/sideMenu/SideMenu";

const CategoryPage = () => {
  const { category } = useParams();
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visibleItems, setVisibleItems] = useState(18);

  useEffect(() => {
    const fetchNewsByCategory = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get(
          `https://api.ekonomimanset.com/category/${category}`
        );
        setNewsItems(response.data);
      } catch (error) {
        console.error(
          "Kategoriye göre haberler alınırken bir hata oluştu:",
          error
        );
        setError("");
      } finally {
        setIsLoading(false);
      }
    };

    if (category) {
      fetchNewsByCategory();
    }
  }, [category]);

  const showMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9);
  };

  return (
    <div className="search-page-container">
      <div className="search-page-top-container md:grid grid-cols-11 md:px-60">
        <div className="search-page-top-left-container col-span-7 md:pr-[30px]">
          <h1 className="search-page-title1">
            "{category}" Kategorisindeki Haberler
          </h1>

          {isLoading ? (
            <p>Haberler yükleniyor...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : newsItems.length === 0 ? (
            <p>Bu kategoride haber bulunamadı.</p>
          ) : (
            <>
              <div className="search-news-grid-container">
                <div className="search-news-grid">
                  {newsItems.slice(0, visibleItems).map((item) => (
                    <div key={item._id} className="search-news-item">
                      <Link to={`/${item.slug}`} target="_blank">
                        <img src={item.image} alt={item.title} />
                        <div className="search-news-content">
                          <h3 className="title">{item.title}</h3>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center">
                {visibleItems < newsItems.length && (
                  <button
                    onClick={showMoreItems}
                    className="load-more-button search-page-load-more-button"
                  >
                    Daha Fazla Sonuç Yükle
                  </button>
                )}
              </div>
            </>
          )}
        </div>

        <div className="search-page-top-right-container col-span-4 md:pl-[30px]">
          <SideMenu />
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;